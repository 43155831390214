'use client';
import { NodeViewRendererProps, NodeViewWrapper } from '@tiptap/react';

const LogoNodeWrapper: React.FC<NodeViewRendererProps> = (props) => {
  const { src } = props.node.attrs;

  return (
    <NodeViewWrapper>
      <img
        data-type="logo"
        src={src}
        style={{
          display: 'block',
          maxHeight: '60px',
          maxWidth: '250px',
          pointerEvents: 'none',
        }}
      />
    </NodeViewWrapper>
  );
};

export { LogoNodeWrapper };
