'use client';

import React from 'react';

import { NodeViewRendererProps, NodeViewWrapper } from '@tiptap/react';

import { ChevronIcon } from '@bloom/ui/components/Icons/Chevron';

export const VariableNodeWrapper: React.FC<{ node: NodeViewRendererProps['node'] }> = (props) => {
  const { node } = props;

  return (
    <NodeViewWrapper
      as="span"
      className="-mt-px inline-flex shrink-0 items-center rounded bg-black py-0.5 align-middle text-xs font-medium whitespace-nowrap text-white"
      style={{ whiteSpace: 'nowrap' }}
    >
      <ChevronIcon className="shrink-0" rotate={90} width={16} />
      {node.attrs.displayValue}
      <ChevronIcon className="shrink-0" rotate={-90} width={16} />
    </NodeViewWrapper>
  );
};
