'use client';
import { NodeViewRendererProps, NodeViewWrapper } from '@tiptap/react';

const AvatarNodeWrapper: React.FC<NodeViewRendererProps> = (props) => {
  const { src } = props.node.attrs;

  return (
    <NodeViewWrapper>
      <div
        data-type="avatar"
        style={{
          backgroundColor: 'rgba(var(--color-mid-grey), 1)',
          backgroundImage: `url(${src})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          borderRadius: '50%',
          height: '40px',
          marginBottom: '10px',
          width: '40px',
        }}
      />
    </NodeViewWrapper>
  );
};

export { AvatarNodeWrapper };
