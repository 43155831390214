import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { format } from 'date-fns';

import { encodeToBase64 } from '@bloom/ui/utils/string';

import { TransformEntityOptions } from '@bloom/library/components/Tiptap/template.server';
import { getUserHostname } from '@bloom/library/utils/browser';
import { momentTz } from '@bloom/library/utils/date';
import { formatAddress, getBusinessNameFromAccount } from '@bloom/library/utils/misc';
import { escapeHTML, formatMoney } from '@bloom/library/utils/string';

import { VariableNodeWrapper } from './Component';

interface VariableNodeProps {
  displayValue: string;
  token: string;
  value: string;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    variable: {
      setVariable: (options: VariableNodeProps) => ReturnType;
    };
  }
}

const VariableNode = Node.create<{
  HTMLAttributes: Record<string, unknown>;
  mode: 'editor' | 'html';
  variablesData: TransformEntityOptions;
}>({
  addAttributes() {
    return {
      displayValue: { default: '' },
      token: { default: '' },
      value: { default: '' },
    };
  },

  addCommands() {
    return {
      setVariable:
        (options) =>
        ({ commands }) =>
          commands.insertContent({ attrs: options, type: this.name }),
    };
  },

  addNodeView:
    typeof window === 'undefined'
      ? undefined
      : () => {
          return ReactNodeViewRenderer(VariableNodeWrapper);
        },

  addOptions() {
    return {
      HTMLAttributes: {},
      mode: 'editor',
      variablesData: {},
    };
  },

  atom: true,
  content: '',
  group: 'inline',
  inline: true,
  name: 'variable',

  parseHTML() {
    return [{ tag: 'span[data-type="variable"]' }];
  },
  renderHTML({ HTMLAttributes, ...rest }) {
    if (this.options.mode === 'editor') {
      return [
        'span',
        mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, { 'data-type': 'variable' }),
      ];
    }

    const { token } = rest.node.attrs;

    const { variablesData } = this.options;

    if (variablesData.variableAsToken) {
      return token;
    }

    switch (token) {
      case '{{BUSINESS_ADDRESS}}': {
        if (variablesData.account) {
          return escapeHTML(formatAddress(variablesData.account.businessAddress));
        }
        return '';
      }
      case '{{BUSINESS_NAME}}':
        if (variablesData.account) {
          const businessName = getBusinessNameFromAccount(variablesData.account);
          if (businessName) {
            return escapeHTML(businessName);
          }

          if (variablesData.user) {
            return escapeHTML(`${variablesData.user.firstName} ${variablesData.user.lastName}`);
          }
        }
        return '';
      case '{{BUSINESS_PHONE}}': {
        if (variablesData.account) {
          return escapeHTML(variablesData.account.businessPhone);
        }
        return '';
      }
      case '{{BUSINESS_WEBSITE}}': {
        if (variablesData.account) {
          return escapeHTML(variablesData.account.businessWebsite);
        }
        return '';
      }
      case '{{CELLO_REFERRAL_LINK}}':
        return [
          'a',
          mergeAttributes({ href: variablesData.referralLink, target: '_blank' }),
          variablesData.referralLink,
        ];
      case '{{CLIENT_ADDRESS}}': {
        const { client } = variablesData;
        if (client) {
          const clientAddress = {
            city: client.city,
            country: client.country,
            postalCode: client.postalCode,
            province: client.province,
            state: client.state,
            street: client.street,
          };

          return formatAddress(clientAddress);
        }
        return '';
      }
      case '{{CLIENT_BUSINESS_NAME}}':
        if (variablesData.client) {
          return escapeHTML(variablesData.client.company);
        }
        return '';
      case '{{CLIENT_FIRST_NAME}}':
        if (variablesData.client) {
          return escapeHTML(variablesData.client.firstName);
        }
        return '';
      case '{{CLIENT_FULL_NAME}}': {
        if (variablesData.client) {
          return escapeHTML(
            `${variablesData.client.firstName || ''} ${variablesData.client.lastName || ''}`.trim()
          );
        }
        return '';
      }
      case '{{CLIENT_LAST_NAME}}': {
        if (variablesData.client) {
          return escapeHTML(variablesData.client.lastName);
        }
        return '';
      }
      case '{{CLIENT_PHONE}}': {
        if (variablesData.client) {
          return escapeHTML(variablesData.client.phone);
        }
        return '';
      }
      case '{{CLIENT_PORTAL_URL}}': {
        const { account, client } = variablesData;
        if (client) {
          const { email, firstName, lastName } = client;

          const hostname = account?.settings
            ? getUserHostname(account.settings.domain, account.settings.customUrl)
            : '';

          if (hostname) {
            const baseUrl = `https://${hostname}/signup`;

            const href = email
              ? `${baseUrl}?inviteCode=${encodeToBase64(JSON.stringify({ email, firstName, lastName }))}`
              : baseUrl;

            return ['a', mergeAttributes({ href, target: '_blank' }), href];
          }

          return '';
        }

        return '';
      }
      case '{{DATE_TODAY}}':
        return format(new Date(), 'MMM do, yyyy');
      case '{{FREELANCER_FIRST_NAME}}':
        if (variablesData.user) {
          return variablesData.user.firstName.trim();
        }
        return '';
      case '{{INVOICE_STATUS}}': {
        const { invoice, project } = variablesData;

        if (invoice?.fundingStatus) {
          return escapeHTML(invoice.fundingStatus);
        }
        if (project?.invoiceStatus) {
          return escapeHTML(project.invoiceStatus);
        }
        return '';
      }
      case '{{MINIMUM_DUE}}': {
        const { project } = variablesData;
        if (project && 'invoiceAmountPaid' in project) {
          return formatMoney(
            Math.max(Number(project.invoiceTotalDue) - Number(project.invoiceAmountPaid), 0),
            project.currencyCode
          );
        }

        return '';
      }
      case '{{PROJECT_DATE}}': {
        const { project } = variablesData;

        if (project?.projectEvent?.startDate) {
          return momentTz(project.projectEvent.startDate, project.timezone).format('MMM Do, YYYY');
        }
        return '';
      }
      case '{{PROJECT_LOCATION}}': {
        const { project } = variablesData;
        if (project?.projectAddress) {
          return formatAddress(project.projectAddress);
        }
        return '';
      }
      case '{{PROJECT_SPECIALTY}}': {
        const { project } = variablesData;
        if (project?.specialty) {
          return escapeHTML(project.specialty);
        }
        return '';
      }
      case '{{PROJECT_TIME}}': {
        const { localeSettings, project } = variablesData;
        if (project?.projectEvent?.startDate) {
          return momentTz(project.projectEvent.startDate, project.timezone).format(
            localeSettings?.timeFormat || 'hh:mma'
          );
        }
        return '';
      }
      case '{{PROJECT_TOTAL}}': {
        const { project } = variablesData;
        if (project && 'projectTotal' in project) {
          return formatMoney(Number(project.projectTotal), project.currencyCode);
        }
        return '';
      }
      default:
        return '';
    }
  },

  selectable: false,
});

export { VariableNode };
