// https://web.dev/articles/base64-encoding

// Quick polyfill since Firefox and Opera do not yet support isWellFormed().
// encodeURIComponent() throws an error for lone surrogates, which is essentially the same.
function isWellFormed(str: string) {
  if (typeof str.isWellFormed !== 'undefined') {
    // Use the newer isWellFormed() feature.
    return str.isWellFormed();
  } else {
    // Use the older encodeURIComponent().
    try {
      encodeURIComponent(str);
      return true;
    } catch (error) {
      return false;
    }
  }
}

export function decodeFromBase64(str: string): string {
  try {
    const binString = atob(str);

    const bytes = Uint8Array.from(binString, (m) => m.codePointAt(0));

    return new TextDecoder().decode(bytes);
  } catch {
    return '';
  }
}

export function encodeToBase64(str: string): string {
  if (isWellFormed(str)) {
    const bytes = new TextEncoder().encode(str);
    const binString = String.fromCodePoint(...bytes);
    return btoa(binString);
  }

  return '';
}

export function escapeFilename(filename: string) {
  // To prevent server error 500 "Invalid character in 'Content-Disposition'"
  // Allow only alpha-numeric (a-zA-Z0-9) characters, white space and -, _
  return filename.replace(/[^\w\s-_.]+/g, '');
}
